import { CommonModule, TitleCasePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { CurrencyFormatModule, CurrencyFormatPipe } from '@fs-design/widgets';
import { DateTimeFormatPipeModule } from "../date-format/date-time-format-pipe.module";
import { DateFormatPipeModule } from "../date-format/date-format-pipe.module";
import { DynamicPipe } from "./dynamic-pipe.pipe";
import { DateTimeFormatPipe } from "../date-format/date-time-format.pipe";
import { DateFormatPipe } from "../date-format/date-format.pipe";

@NgModule({
    declarations:[
        DynamicPipe
    ],
    imports:[
        CommonModule,
        DateTimeFormatPipeModule,
        CurrencyFormatModule,
        DateFormatPipeModule
    ],
    providers:[
        TitleCasePipe,
        CurrencyFormatPipe,
        DateTimeFormatPipe,
        DateFormatPipe
    ],
    exports:[
        DynamicPipe
    ]
})

export class DynamicPipeModule {}