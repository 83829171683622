import { transactionType, loanType } from '../enum/kyc.enum';

const base_configuration = {
  date_format: {
    date: 'dd MMMM yyyy',
    date_time_not_second: 'yyyy-MM-dd HH:mm',
    date_time: 'dd MMMM yyyy HH:mm:ss',
    locale: 'en-SG',
    timezone: 'Asia/Singapore',
  },
  year_difference: 0,
  prime_calendar: 'yy-mm-dd',
  number_format: {
    align: 'left',
    allowNegative: false,
    decimal: '.',
    precision: 2,
    prefix: 'S$ ',
    suffix: '',
    thousands: ',',
  },
  pipe: {
    precision: '1.2-2',
  },
  member_typeid_borrower: 'BRW',
  member_typeid_cf_borrower: 'CFB',
  member_typeid_investor: 'INV',
  member_typeid_company: 'COM',
  member_typeid_partner: 'PRT',
  member_typeid_cashback: 'CB',
  AllMembers: '',
  underwriting_enable_elo_product: [8],
  google_map_api_key: 'AIzaSyA67Ex58yCakUHzXyS-Son0nt6lmASBdgE',
  enable_mail_disbursal: false,
  enable_questnet: false,
  enable_ctos: false,
  enable_pefindo: false,
  show_bank_integration_section: false,
  show_dopa_card_status_check: false,
  show_second_identity_card_number: false,
  second_identity_card_number_title: '',
  subDistrictDetailEnabled: ['TH'],
  display_crowdfunding_segmentation: false,
  should_auto_send_activation_notification: true,
  hide_pii_post_kyc: false,
  hide_pii_in_member_detail: false,
  primary_ic_type_id: null,
  show_virtual_account_number_generator: {
    borrower: false,
  },
  show_multiple_virtual_account_number: {
    investor: false,
    borrower: false,
  },
  investor_detail: {
    show_ic_expiry_date: false,
  },
  show_investor_bond_certificate_download: false,
  add_doc_type_and_loan_code_to_loan_agreement_filename: false,
  defaultBranch: '',
  enableGSTCalculationForRepayment: false,
  gstRate: 0,
  show_distribution_gst: false,
  show_risk_category_fields: true,
  show_cod_options: false,
  kycSubstitution: {
    KYC: '100K KYC',
    APPLICATION: 'Application',
    BNPL: transactionType.BNPL,
    VCC: transactionType.VCC,
    TERM_LOAN: loanType.TERM_LOAN,
    CREDIT_LINE: loanType.CREDIT_LINE,
    ONLY_PARTNERS: [],
  },
  showFacilityContractNumber: false,
  showFacilityDigitalSignature: false,
  show_wht_adjustment_tab: true,
  show_compliance_validation_reason: false,
  show_wht_breakdown: false,
  enableSubscribeMainToProxyLoanDocTypes: false,
  toggleAdminFeeHelperText: false,
  inclusive_withholding_tax: false,
  showCompanyDFConfig: false,
  showFacilityID: true,
  showLoanID: false,
  offerTabDigiSign: false,
  additionInfoTab: false,
  triggerMicroLoanService: true,
  showBNPLOption: true,
  showVCCOption: true,
  showTermLoanOption: false,
  selectedProductType: [transactionType.VCC, transactionType.BNPL],
  has_buyer_van: false,
  calculator: {
    settlement: {
      show_calculated_early_repayment: false,
      show_gst: false,
    },
  },
  capture_loan_disbursement_and_repayment_methods: false,
  allowFacilityCreationWithAllTypes: false,
  defaultFundingRestriction: false,
  autoInvestment: {
    hasIslamicProduct: false,
  },
  multi_wallet: false,
  updateLoanDisbursementMethodUsingBankCountryParty: false,
  enableLoanFinancingModel: false,
  enableIDDiscountingModel: false,
  enablePushToSilkRoad: false,
  defaultPreApprovedValues: {},
  enableCalculateRepaymentFee: false,
  facility: {
    showFlag: false,
  },
  showFacilityContractDate: false,
  useIDInvestorChannleing: false,
  showLineId: false,
  showComplianceSIK: false,
  smeIssuer: {
    display: false,
    items: [],
  },
};

export default base_configuration;
