const vu_configuration = {
  country_code: 'VU',
  currency: 'USD',
  date_format: {
    date: 'dd MMMM yyyy',
    date_short: 'yyyy-MM-dd',
    date_short_moment: 'YYYY-MM-DD',
    date_short_dmy: 'dd/MM/yyyy',
    date_time: 'dd MMMM yyyy HH:mm:ss',
    date_time_not_second: 'yyyy-MM-dd HH:mm',
    locale: 'en-VU',
    timezone: 'Asia/Ho_Chi_Minh',
  },
  number_format: {
    align: 'left',
    allowNegative: false,
    decimal: '.',
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    locale: 'en',
  },
  fee_conversion_format: {
    usd_to_vnd: {
      target_locale: 'en-VN',
      currency_prefix: '₫',
    },
  },
  pipe: {
    precision: '1.2-2',
  },
  language: {
    default: 'en',
    show: false,
  },
  loan: {
    underwriting: {
      guaranteeFee: {
        show: false,
      },
    },
  },
  show_generate_instruction: true,
  primary_ic_type_id: 28, // currently KTP - will need to change it when backend is ready
  hide_blank_dropdown_options: {
    industry: false,
  },
  phoneValidation: {
    code: '+84',
    phoneNumberLengthWithCode: [12, 13],
    phoneNumberLengthWithoutCode: [9, 10],
  },
  show_user_type_column_in_facility_user: true,
  show_bca_va_reconciliation_upload: false,
  use_custom_investor_management_pagination: false,
  use_custom_borrower_management_pagination: false,
  use_custom_partner_management_pagination: false,
  defaultBranch: 'HCMC',
  inclusive_withholding_tax: false,
  has_usd_to_local_currency_conversion: true,
  has_buyer_van: true,
  enablePushToSilkRoad: false,
};

export default vu_configuration;
