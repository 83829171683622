import { Config, Type } from './config';

export const environment = {
  version: '{BUILD_VERSION}',
  production: false,
  name: 'uat',
  api: 'https://api-internal-uat.modalku.co.id/internal',
  base_website_url: 'https://web-uat-commandcenter.modalku.co.id',
  Base_api_url: 'https://api-internal-uat.modalku.co.id',
  Auth_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/auth',
  Parsing_service_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/dps',
  Bond_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/v1/esbn',
  // Deprecated: use External_Endpoint instead.
  CTOS_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/ext',
  // Deprecated: use External_Endpoint instead.
  Questnet_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/ext',
  External_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/ext',
  FA_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/v2/fa',
  Document_EndPoint_V3: 'https://api-internal-uat.modalku.co.id/internal/api/docs_management/v3',
  Finops_Comms_Service_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/fcs', // new cf orchestration service
  DocGen_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/dg',
  Finance_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/fs',
  Finance_Endpoint_V2: 'https://api-internal-uat.modalku.co.id/internal/api/fs/v2',
  Finance_Pairing_Endpoint:
    'https://api-internal-uat.modalku.co.id/internal/api/finance_pairing/v1',
  Member_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/ms',
  Member_EndPoint_V2: 'https://api-internal-uat.modalku.co.id/internal/api/v2/members',
  SOA_EndPoint_V2: 'https://api-internal-uat.modalku.co.id/internal/api/v2/soa-reports',
  Loan_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/ln',
  LookUpNodeJS_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/lu/p',
  Loyalty_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/v1/loyalty',
  SMS_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/sms',
  Scheduler_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/sc',
  Logout_URI: 'https://api-internal-uat.modalku.co.id/internal/api/logout?redirect=',
  Logout_Page_URL: 'https://api-internal-uat.modalku.co.id/internal/login/logout.html?home=',
  Login_URI: 'https://api-internal-uat.modalku.co.id/internal/login/?redirect_uri=',
  Notification_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/notification',
  Email_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/email',
  Staff_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/ss',
  User_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api',
  Kyc_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/kyc/v1',
  CustomModule_Endpoint: 'https://api-internal-uat.modalku.co.id:30051',
  MicroLoan_Endpoint: 'https://uat-microloan.fundingasiagroup.com',
  MicroLoan_Docs_Gen_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/docs_gen',
  MicroLoan_Internal_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/ml',
  Underwriting_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/credit',
  Credit_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/credit/v2',
  v2_url: 'https://api-internal-uat.modalku.co.id/internal/api/v2',
  Partnership_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/partners',
  Collection_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/v1/loan-collections',
  Dispatch_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/dispatch',
  Facility_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/facility',
  Facility_Endpoint_V2: 'https://api-internal-uat.modalku.co.id/internal/api/facility/v2',
  Investor_Channelling_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/ics',
  ELO_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/elo_rating',
  ELO_Rating_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/credit/v2/elo',
  Paylater_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/paylater',
  myTukar_account_Id: 819726,
  carlistbid_account_id: 833585,
  MUV_account_Id: 819776,
  launch_darkly_client_id: '5da572b4a6f14808cb9c58cf',
  Bolt_Partner_EndPoint: 'https://api-internal-uat.modalku.co.id/internal/api/bpp',
  Borrower_Backend_Service_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/bbs',
  Ekyc_Esign_Service_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/ees',
  Bank_Integration_Service_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/bis',
  Business_Account_Endpoint: 'https://api-internal-uat.modalku.co.id/internal/api/bas',
  scfs: 'https://staging-scfs2.fundingasiagroup.com',
  bowIDOrigin: 'https://apply-uat.modalku.co.id',
  carsome_account_Id: 1068468,
  ezauto_account_Id: 1211829,
  hideNonMKItem: true, // For ID Audit
  hideNonFSItem: false, // For ID Audit
  download_soa: `https://kong-internal-uat.fundingasiagroup.com/internal/api/dg/v1/document`,
  type: new Config(Type.UAT),
  template_id: '78e0017d-65ab-4c37-8439-2426eca675b9',
  sheet_name: 'Sheet1',
  qlmProductIds: [16, 13, 209],
  enablePrivy: true,
  productIds: {
    islamicMicrofinancingMY: 210,
    islamicEcommerceMY: 211,
  },
  loanLevelSOA: {
    SG: {
      fs_capital: {
        template_id: '0ab3b9f9-6b01-4c89-9dc6-e1265d0f79d0',
      },
      fspl: {
        template_id: '9f3a9cba-7bae-41de-a555-30785811984f',
      },
    },
  },
  Appsmith_Scheduler_Url:
    'https://appsmith-uat.modalku.co.id/app/scheduler-tool/create-new-schedule-664f639ecb400a6c8356596b',
  masterCustomerData: {
    individualsUrl: '',
    companiesUrl: '',
  },
  datadogConfiguration: {
    applicationId: 'c1d00e6a-385b-4ec3-b623-c2924566427c',
    clientToken: 'pubc83c201656cc2c04546bb59e396d08f2',
  },
};
