import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from '@shared-components/modal';
import { NgxPaginationModule } from 'ngx-pagination';
import { ControlsModule } from '../controls/controls.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmationModalModule } from '@shared-components/confirmation-modal/confirmation-modal.module';
import { ModalUpdateCTOSInfoComponent } from './modal-update-ctos-info/modal-update-ctos-info.component';
import { ImageViewerModule } from 'lacuna-image-viewer';
import { ToastMessagesComponent } from '@shared/components/toast-messages/toast-messages.component';
// Temporary until finance component ready
import { CurrencyMaskModule } from '@shared/directive/currency-mask/currency-mask.module';

// Language
import { TranslateModule } from '@ngx-translate/core';
import { DocViewerComponent } from './doc-viewer/doc-viewer.component';
import { FormGroupComponent } from '@shared-components/form-group/form-group.component';
import { TableModule } from './table';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { CurrencyFormatModule } from '@fs-design/widgets';
import { SafeTransformModule } from '@shared/pipes/safe-transform/safe-transform.module';
import { DateFormatPipeModule } from '@shared/pipes/date-format/date-format-pipe.module';
import { DateTimeFormatPipeModule } from '@shared/pipes/date-format/date-time-format-pipe.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ModalModule,
    NgxPaginationModule,
    FormsModule,
    PipesModule,
    SafeTransformModule,
    CurrencyFormatModule,
    DateFormatPipeModule,
    DateTimeFormatPipeModule,
    ReactiveFormsModule,
    ControlsModule,
    CalendarModule,
    CurrencyMaskModule,
    TranslateModule,
    TableModule,
    ImageViewerModule.forRoot({
      btnClass: 'default',
      allowFullscreen: false,
      btnIcons: {
        zoomIn: 'fa fa-plus',
        zoomOut: 'fa fa-minus',
        rotateClockwise: 'fa fa-repeat',
        rotateCounterClockwise: 'fa fa-undo',
      },
    }),
    ConfirmationModalModule,
  ],
  declarations: [
    ModalUpdateCTOSInfoComponent,
    ToastMessagesComponent,
    DocViewerComponent,
    FormGroupComponent,
  ],
  exports: [
    ModalUpdateCTOSInfoComponent,
    ToastMessagesComponent,
    DocViewerComponent,
    FormGroupComponent,
    ConfirmationModalComponent,
  ],
  providers: [PipesModule],
})
export class ComponentsModule {}
