import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { ReplaySubject, Observable } from 'rxjs';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { ProfileService } from '../services/profile.service';
import { MessageService } from './message.service';

declare let TextEncoder: any;

/* The following code can be copied to another component to integrate with the
 feature flag.

    const { ctos } = this.featureFlagService.getFeatureFlagKeys();
    this.featureFlagObservable = this.featureFlagService.getFeatureFlagObservable();
    this.featureFlagObservable.subscribe( (flags) => {
      this.enableCtos = flags[ctos];
    });
*/

@Injectable()
export class FeatureFlagService {
  ldClient: LDClient;
  flags: LDFlagSet;
  flagChangedMessage: ReplaySubject<LDFlagSet> = new ReplaySubject();
  messageServiceObservable: Observable<any>;
  enableFlagDebug = false;
  featureFlagKeys: any;

  constructor(
    private profileService: ProfileService,
    private messageService: MessageService
  ) {
    this.featureFlagKeys = Object.freeze({
      bniIntegration: 'enable-bni-integration',
      collection: 'enable-collection',
      perfios: 'enable-perfios',
      creditScoring: 'credit-scoring',
      pefindo: 'enable-pefindo',
      onfido: 'enable-my-onfido',
      datadog: 'enable-datadog-rum',
      showVpnSwitcher: 'show-openvpn-switcher',
      eloRating: 'enable-elo-rating',
      disableVrAndDsTabs: 'disable-vr-and-ds-tabs',
      maintenanceMode: 'command-center-maintenance-mode',
      showAllStaffList: 'show-all-staff-list',
      enableSGVans: 'enable-sg-vans',
      hideSgFactsheetDocumentGeneration: 'hide-sg-factsheet-document-generation',
      useDocgenV2ForMySoa: 'use-docgen-v2-for-my-soa',
      useDocgenV2ForTfFactsheet: 'use-docgen-v2-for-tf-factsheet',
      enableApFinanceModel: 'enable_ap_finance_model',
      enableCreditLineTermination: 'enable-credit-line-termination',
      enableNonTemplateVidaEsignFlow: 'enable-non-template-vida-esign-flow',
      bulkDistributionSupportedCountries: 'bulk-distribution-supported-countries',
      finopsEnablePredisbursal: 'finops-enable-predisbursal',
      enableLinksToMasterCustomerDataUi: 'enable-links-to-master-customer-data-ui',
      useDocgenV2TemplateApiForUpload: 'docgen_use_template_v2_apis',
    });
    this.flags = {};
    this.messageServiceObservable = this.messageService.getLoginSubject();
    this.messageServiceObservable.subscribe((event) => {
      if (event === 'initializeLdClient') {
        this.initializeLdClient();
      }
    });
  }
  async getContext() {
    const countryCode = this.profileService.getCountryId();
    const username = this.profileService.getUserName();
    const userRole = this.profileService.getCurrentRole();
    if (!countryCode || !username || !userRole) {
      return false;
    }
    const userInfo = [username, countryCode, 'launchdarkly'].join('-');
    const hexUserId = await this.digestMessage(userInfo);
    return {
      kind: 'user',
      key: hexUserId,
      email: username,
      country: countryCode,
      userRole,
    };
  }

  async initializeLdClient() {
    const context = await this.getContext();
    if (!context) {
      if (this.enableFlagDebug) {
        console.info('No context is found');
      }
      return;
    }
    this.ldClient = initialize(environment.launch_darkly_client_id, context, {
      bootstrap: 'localStorage',
    });
    this.ldClient.on('ready', () => {
      this.setFlags(this.ldClient.allFlags());
    });
    this.ldClient.on('change', (flags) => {
      this.setFlags(flags, true);
    });
  }

  async digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
  }

  setFlags(flags, change = false) {
    if (change) {
      const changes = {};
      Object.entries(flags).forEach(([key, value]) => {
        changes[key] = value['current'];
      });
      this.flags = Object.assign(this.flags, changes);
    } else {
      this.flags = Object.assign(this.flags, flags);
    }
    if (this.enableFlagDebug) {
      console.log('change -> flags: \n', JSON.stringify(this.flags, null, 2));
    }
    this.flagChangedMessage.next(this.flags);
  }

  getFeatureFlagKeys() {
    return this.featureFlagKeys;
  }

  getFeatureFlagObservable() {
    return this.flagChangedMessage.asObservable();
  }

  closeLdClient() {
    if (this.ldClient) {
      this.ldClient.close();
    }
  }
}
