import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormGroupOptionService } from './services/form-group-option.service';
import { FormListOptionService } from './services/form-list-option.service';
import { LoadingComponent } from './components/loading';
import { PipesModule } from '@shared/pipes/pipes.module';
import { RadioComponent } from './components/radio/radio.component';
import { CurrencyMaskModule } from '@shared/directive/currency-mask/currency-mask.module';
import { LoadingModule } from './components/loading/loading.module';
import { CurrencyFormatModule, NumericFormatModule } from '@fs-design/widgets';

@NgModule({
  declarations: [RadioComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    CurrencyFormatModule,
    NumericFormatModule,
    CurrencyMaskModule,
    LoadingModule,
  ],
  exports: [LoadingComponent, RadioComponent],
  providers: [FormGroupOptionService, FormListOptionService],
  bootstrap: [],
})
export class ControlsModule {}
