import { environment } from '../environments/environment';

export const MENU = {
  SG: [
    { value: 'docs', label: 'Docs' },
    { value: 'myinfo', label: 'MyInfo' },
    { value: 'summary', label: 'Summary' },
    { value: 'note', label: 'Notes' },
    { value: 'elo', label: 'ELO Ranking' },
    { value: 'bank-statement', label: 'Bank Statements' },
    { value: 'brc', label: 'BRC' },
    { value: 'bri', label: 'BRI' },
    { value: 'cbs', label: 'CBS' },
    { value: 'notification', label: 'Notification' },
    { value: 'underwriting-decision', label: 'Underwriting Decision' },
    { value: 'contract-generation', label: 'Contract Generation' },
    { value: 'collection', label: 'Collection' },
    { value: 'instalment', label: 'Instalment' },
  ],
  MY: [
    { value: 'docs', label: 'Docs' },
    { value: 'ekycs', label: 'eKYCs' },
    { value: 'note', label: 'Notes' },
    { value: 'bank-statement', label: 'Bank Statements' },
    { value: 'notification', label: 'Notification' },
    { value: 'underwriting-decision', label: 'Underwriting Decision' },
    // { value: 'funding-info', label: 'Funding Info' },
    { value: 'contract-generation', label: 'Contract Generation' },
    { value: 'collection', label: 'Collection' },
    { value: 'instalment', label: 'Instalment' },
  ],
  ID: [
    { value: 'docs', label: 'Docs' },
    { value: 'pre-screening', label: 'Pre-screening' },
    { value: 'note', label: 'Notes' },
    { value: 'bank-statement', label: 'Bank Statements' },
    { value: 'bpjs', label: 'BPJS' },
    { value: 'location', label: 'Location' },
    { value: 'notification', label: 'Notification' },
    { value: 'underwriting-decision', label: 'Underwriting Decision' },
    // { value: 'funding-info', label: 'Funding Info' },
    { value: 'collection', label: 'Collection' },
    { value: 'instalment', label: 'Instalment' },
  ],
};

export const BOLT_SG_UNDERWRITING_DOC_TYPES = [
  { value: 'OFFER_LETTER', name: 'Offer Letter' },
  { value: 'SERVICE_FEE_AGREEMENT', name: 'Service Fee Agreement' },
  { value: 'INVESTMENT_NOTE_FACILITY_AGREEMENT', name: 'Investment Note Facility Agreement' },
  { value: 'CONFIRMATION_CERTIFICATE', name: 'Confirmation Certificate' },
  { value: 'BORROWER_BOARD_RESOLUTION', name: 'Borrower Board Resolution' },
];

export const BOLT_MY_UNDERWRITING_DOC_TYPES = [
  { value: 'OFFER_LETTER', name: 'Offer Letter' },
  { value: 'INVESTMENT_NOTE_FACILITY_AGREEMENT', name: 'Investment Note Facility Agreement' },
];

export const M1CB_UNDERWRITING_DOC_TYPES = [
  { name: 'Offer Letter', value: 'OFFER_LETTER' },
  { name: 'Service Fee Agreement', value: 'SERVICE_FEE_AGREEMENT' },
  { name: 'Investment Note Facility Agreement', value: 'INVESTMENT_NOTE_FACILITY_AGREEMENT' },
  { name: 'Confirmation Certificate', value: 'CONFIRMATION_CERTIFICATE' },
  { name: 'Borrower Board Resolution', value: 'BORROWER_BOARD_RESOLUTION' },
];

export const COMPANY_TYPE_LIST = ['Private Limited', 'Sole Proprietor', 'Partnership'];

export const TEST_BUCKET_LIST_SG = [
  'None',
  'Hard Reject without Default Records',
  'CBS Defaults',
  'Suspected doctored document/s',
  'PSG-Xintesys ERP',
  'PSG-A2000 ERP',
  'IGP-E2I',
  'PSG-DIMMS',
  'EDG-HRC',
  'Model',
  'Favepay',
  'Foreign ownership',
];
export const TEST_BUCKET_LIST_MY = [
  'None',
  'New company',
  'No credit history',
  'Low ending balance',
  'Adverse Record',
  'Phone Interview',
];

export const SG_BOLT_TYPE_ID = 14;
export const MY_BOLT_TYPE_ID = 18;

export const NOTES_TYPE = {
  sales: 'sales',
  preDisbursement: 'pre-disbursement',
};

export const ID_M1CB_BRAVEN_PRODUCT_ID = 27;
export const ID_M1CB_RED_RAVEN_PRODUCT_ID = 63;
export const ID_M1CB_SILVER_RAVEN_PRODUCT_ID = 67;
export const ID_M1CB_WHITE_RAVEN_PRODUCT_ID = 82;
export const ID_M1CB_COPPER_RAVEN_PRODUCT_ID = 79;

export const M1CB_BRAVEN_TYPE_ID = 15;
export const M1CB_TYPE_ID = 15;
export const SG_BOLT_PRODUCT_ID = 11;
export const MY_BOLT_PRODUCT_ID = 25;
export const ID_M1CB_PRODUCT_ID = 21;
export const MY_BOLT_GRAB_PRODUCT_ID = 65;
export const MY_ECOM_FINANCING_PRODUCT_ID = 57;
export const SG_PHONE_PREFIX = '+65';
export const SMS_FROM_NUMBER = '+6591841651';
export const M1CB_SMS_FROM = 'Modalku';
export const WHATSAPP_WEB_API = 'https://api.whatsapp.com/send?phone=';

export const MY_BOLT_NOTIFICATIONS = [
  {
    value: 'document_request',
    name: 'Document request',
  },
];

export const MICRO_LOAN_COUNTRY_CODE_MAPPING = [
  { id: 11, countryCode: 'SG' },
  { id: 25, countryCode: 'MY' },
  { id: 21, countryCode: 'ID' },
  { id: 27, countryCode: 'ID' },
  { id: 63, countryCode: 'ID' },
  { id: 65, countryCode: 'MY' },
  { id: 67, countryCode: 'ID' },
  { id: 79, countryCode: 'ID' },
  { id: 82, countryCode: 'ID' },
  { id: 57, countryCode: 'MY' },
  { id: environment.productIds.islamicMicrofinancingMY, countryCode: 'MY' },
  { id: environment.productIds.islamicEcommerceMY, countryCode: 'MY' },
];

export const MY_BOLT_EMAIL_TEMPLATES = {
  document_request: {
    subject: 'One last step to your application with FS Bolt!',
    content:
      '<p>Hi *name*,</p>' +
      "<p>Thank you for your application. It'd be great if you could send us the following documents the soonest for us to proceed further :</p>" +
      '<ul>*docs*</ul>' +
      '<p>If you have other documents which you feel may help (i.e. ongoing contracts/ invoices), please send them in as well. ' +
      'You could either upload them via the app or reply to this email with the attachments.</p>' +
      '<p>Please feel free to reach out should you have any questions. Thank you.</p>',
  },
};

export const MY_BOLT_REQUEST_DOCS = [
  { value: 'bolt_consent_letter', name: 'Consent Letter', checked: false },
  { value: 'bolt_nric', name: 'NRIC', checked: false },
  { value: 'bolt_income_tax', name: 'Income Tax', checked: false },
  { value: 'bolt_bank_statements', name: 'Bank statements', checked: false },
];

export const MY_DOC_REQUEST_TEMPLATE = {
  bolt_consent_letter:
    '<li>Signed by all director & company stamp on the consent letter (as attached)</li>',
  bolt_nric: '<li>NRIC Front & Back (all directors)</li>',
  bolt_income_tax: '<li>Past 2 Years Personal Income Tax Assessment (all directors)</li>',
  bolt_bank_statements:
    '<li>PDF copy of last 6 months bank statements from your business bank account/accounts. ' +
    "In case there isn't much activity and/or you don't have a business bank account, please do attach your " +
    'personal bank statements and/or payslips so we can process your application!</li>',
};

export const ID_M1CB_NOTIFICATIONS = [
  { value: 'm1cb_user_nopickup', name: 'User not picking up phone' },
  { value: 'm1cb_pending_contract', name: 'Pending Contract Signing' },
  { value: 'm1cb_loan_ongoing', name: 'Set to Ongoing loan' },
  { value: 'm1cb_before_dd', name: 'Reminder before DD' },
  { value: 'm1cb_after_dd', name: 'Reminder after DD' },
  { value: 'custom', name: 'Custom' },
];

export const ID_M1CB_REQUEST_DOCS = [{ value: 'm1cb_bank_statements', name: 'Bank statements' }];

export const DOC_REQUEST_TEMPLATE = {
  bolt_bank_statements:
    "- PDF copy of last 6 months bank statements from your business bank account/accounts. In case there isn't much activity and/or you don't have a business bank account, please do attach your personal bank statements and/or payslips so we can process your application!",
  bolt_pay_slips: '- Copy of last 6 months of payslips.',
};

export const SMS_TEMPLATES = {
  m1cb_user_nopickup:
    'Kami baru saja mencoba menelepon Anda mengenai aplikasi pinjaman Anda ke Modalku. Mohon periksa dan balas email kami untuk melanjutkan proses aplikasi. -Modalku',
  m1cb_pending_contract:
    'Silakan tanda tangani Perjanjian Pinjaman Anda di Aplikasi Modalku Dana Usaha agar dana pinjaman Anda dapat segera kami cairkan. -Modalku',
  m1cb_loan_ongoing:
    'Pelanggan Yth., dana pinjaman Anda telah kami cairkan. Silakan cek aplikasi Modalku Dana Usaha untuk informasi lebih lanjut dari kami. -Modalku',
  m1cb_before_dd:
    'Angsuran pinjaman Anda akan segera jatuh tempo. Mohon lakukan pembayaran ke rek VA Modalku Anda unt menghindari denda. Abaikan jika sudah membayar. -Modalku',
  m1cb_after_dd:
    'Angsuran pinjaman Anda telah jatuh tempo. Mohon segera melakukan pembayaran (+ denda) ke rek VA Modalku Anda. Abaikan jika sudah membayar. -Modalku',
};

export const PN_TEMPLATES = {
  m1cb_user_nopickup: 'Ada panggilan tidak terjawab dr Modalku',
  m1cb_pending_contract: 'Silakan tanda tangani kontrak Anda',
  m1cb_loan_ongoing: 'Dana pinjaman Anda telah kami cairkan',
  m1cb_before_dd: 'Angsuran Anda akan segera jatuh tempo',
  m1cb_after_dd: 'Angsuran Anda telah jatuh tempo',
};

export const SG_BOLT_NOTIFICATIONS = [
  { name: 'First touch follow-up', value: 'first_touch_follow_up' },
  { name: 'Document request', value: 'document_request' },
  { name: 'Offer Letter', value: 'offer_letter' },
  { name: 'Contract Copy', value: 'contract_copy' },
  { name: 'Contract and Giro DDA form', value: 'contract_giro' },
  { name: 'Reminder: Forms', value: 'reminder_forms' },
  { name: 'Renewal Pitch - with Survey', value: 'renewal_pitch_with_survey' },
  { name: 'Closure Letter - Renewed already', value: 'closure_letter_renewed_already' },
  { name: 'Closure Letter - with renewal pitch', value: 'closure_letter_renewal_pitch' },
  { name: 'OCBC Partnership', value: 'ocbc_partnership' },
];

export const SG_BOLT_REQUEST_DOCS = [
  { value: 'bolt_nric', name: 'NRIC', checked: false },
  { value: 'bolt_cpf', name: 'CPF', checked: false },
  { value: 'bolt_cbs', name: 'CBS', checked: false },
  { value: 'bolt_bank_statements', name: 'Bank statements', checked: false },
  { value: 'bolt_noa', name: 'Notice of Assessment', checked: false },
  { value: 'bolt_invoice', name: 'Invoice', checked: false },
  { value: 'bolt_contracts', name: 'Contracts', checked: false },
];

export const SG_DOC_REQUEST_TEMPLATE = {
  bolt_nric: '<li>Your NRIC copy (front & back)</li>',
  bolt_cpf: '<li>CPF statement (past 2 years transaction history)</li>',
  bolt_cbs:
    '<li>CBS dated within the last 15 days (A report can be purchased from https://www.creditbureau.com.sg/buy-my-credit-report.html)</li>',
  bolt_bank_statements:
    '<li>PDF copy of last 6 months bank statements from your business bank account/accounts. ' +
    "In case there isn't much activity and/or you don't have a business bank account, please do attach " +
    'your personal bank statements and/or payslips</li>',
  bolt_noa:
    "<li>Past 2 years' NOA (Notice of Assessment) which you can get at https://mytax.iras.gov.sg/ESVWeb/default.aspx</li>",
  bolt_invoice: '<li>Invoice</li>',
  bolt_contracts: '<li>Contracts</li>',
};

export const SG_BOLT_EMAIL_TEMPLATES = {
  first_touch_follow_up: {
    subject: 'Your FS Bolt Application',
    content: `Hey there!<br>
      <br>
      I'm Ivie, your dedicated Account Manager. I'm very excited to have you onboard!<br>
      <br>
      I noticed that you tried our app recently but did not finish the process. Are you looking for business financing? We'd be glad to help!<br>
      <br>
      Please let me know if there were any problems, or if there's anything at all I can help you with. You can always just respond to this email or Whatsapp me at: +65 87788693.<br>
      <br>
      In case you were looking to pick up where you left off, all you need to do is launch the app and just continue from there.<br>
      <br>
      <br>
      Warm regards,<br>
      Ivie`,
  },
  document_request: {
    subject: 'One last step to your application with FS Bolt!',
    content: `Hi *name*<br><br>
      Thank you for your application. It'd be great if you could send us the following documents the soonest for us to proceed further:
      <ul>*docs*</ul>
      If you have other documents which you feel may help (i.e. ongoing contracts/ invoices), please send them in as well.  You could either upload them via the app or reply to this email with the attachments.<br><br>
      Please feel free to reach out should you have any questions. Thank you.<br><br><br>
      Warm regards,<br>
      Ivie`,
  },
  offer_letter: {
    subject: '*loancode* - Offer Letter',
    content: `Hi *name*<br><br>
      Congratulations on your loan approval!<br><br>
      Please refer to the attached Loan Offer for the details. You may reply to this email with your acceptance.<br><br>
      If you have any questions, please feel free to reach out. Thank you!<br><br><br>
      Warm regards,<br>
      Ivie`,
  },
  contract_copy: {
    subject: '*loancode* - Your Contract (copy)',
    content: `Hi *name*<br><br>
      We are pleased to inform you that your loan was successfully disbursed. Attached is the Loan Contract for your records.<br><br>
      If you have any questions, please feel free to reach out.  Thank you.<br><br><br>
      Warm regards,<br>
      Ivie`,
  },
  contract_giro: {
    subject: '*loancode* - Your Contract (copy) and Required Forms',
    content: `Hi *name*<br><br>
      We are pleased to inform you that your loan was successfully disbursed. Attached is the Loan Contract for your records.<br><br>
      I am also sending you the required forms for this loan.  Kindly mail us the signed forms within 3 working days.  Please address it to:  <strong>91 Bencoolen Street,  #07-01 Sunshine Plaza, Singapore 189652.</strong><br><br>
      If you have any questions, please feel free to reach out.  Thank you<br><br><br>
      Warm regards,<br>
      Ivie`,
  },
  reminder_forms: {
    subject: '*loancode* - We have yet to receive the required form/s',
    content: `Hi *name*<br><br>
    Please refer to our previous email with the required forms for the current loan. Kindly mail us the signed forms within 3 working days. Please address it to:  <strong>91 Bencoolen Street,  #07-01 Sunshine Plaza, Singapore 189652</strong>.<br><br>
    If you have any questions, please feel free to reach out.  Thank you<br><br><br>
    Warm regards,<br>
    Ivie`,
  },
  renewal_pitch_with_survey: {
    subject: '*loancode* Your loan is ending soon!',
    content: `Hi *name*<br><br>
      How are you? With one repayment left, your loan with us will soon be completed.<br><br>
      We'd love to know about your experience with us so far so that we can work on the areas we may improve on. I hope you can spare us a few minutes to answer the following:<br><br>
      1.&nbsp; Did you meet your objective with the financing from us?<br><br>
      2.&nbsp; Do you have any feedback on our processes/communications?<br><br>
      3.&nbsp; How do we compare against other loan providers?<br><br>
      4.&nbsp; Do you have any near-term financing requirements?<br><br>
      5.&nbsp; How can we be your first choice for financing solutions?<br><br>
      6.&nbsp; Would you be willing to leave us a testimony and/or review?<br><br>
      Your comments are greatly appreciated! We promise to read everything and work on being better.<br><br>
      On another note, we'll be glad to assist you if you are keen to&nbsp;<strong>apply for a renewal.</strong>&nbsp; As a welcome back offer, we will be&nbsp;<strong>reducing up to 20% of the service fee</strong>&nbsp;should you make an application within 30 days after current loan has been completed!  Simply submit an application through the app or reply to this email to indicate your interest.<br><br><br>
      Warm Regards,<br>
      Ivie`,
  },
  closure_letter_renewed_already: {
    subject: '*loancode* - Loan Closure',
    content: `Hi *name*<br><br>
      Thank you for making the full payment for your FS Bolt loan.  Attached is the closure letter for your records.<br><br>
      If you have any existing GIRO arrangement with us, kindly ensure the cancellation by calling your bank.<br><br>
      If you have any questions, please feel free to reach out. :)<br><br>
      Warm Regards,<br>
      Ivie `,
  },
  closure_letter_renewal_pitch: {
    subject: '*loancode* - Loan Closure',
    content: `Hi *name*<br><br>
      Thank you for making the full repayment for your FS Bolt loan. Attached is the closure letter for your records.<br><br>
      If you have any existing GIRO arrangement with us, kindly ensure the cancellation by calling your bank.<br><br>
      We'll be happy to assist you again if you are keen to apply for a renewal.&nbsp; Simply submit an application through the app or reply to this email to indicate your interest.<br><br>
      Looking forward to working with you again!<br><br><br>
      Warm Regards<br>,
      Ivie`,
  },
  ocbc_partnership: {
    subject: 'Congratulations - Your application has been pre-approved!',
    content: `<div
    style="color:rgb(80,0,80);font-family:Roboto,RobotoDraft,Helvetica,Arial,sans-serif;background-color:rgb(220,227,225)">
    <div
      id="m_-3489806180884640543m_3776847492272040756m_-1384876653450068671m_-5841057114198344580gmail-m_-6730822537853258378gmail-m_-3097863656750721744gmail-m_-602539883475343097m_-7853305765633135062gmail-m_300081265866230006m_2806775055833281088m_5885355420619254025m_-5615617117921539749emb-email-header-container"
      style="margin:0px auto;max-width:600px;min-width:320px">
      <div align="center" style="line-height:32px;margin:6px 20px 20px;color:rgb(195,206,217)">
        <div align="left"
          id="m_-3489806180884640543m_3776847492272040756m_-1384876653450068671m_-5841057114198344580gmail-m_-6730822537853258378gmail-m_-3097863656750721744gmail-m_-602539883475343097m_-7853305765633135062gmail-m_300081265866230006m_2806775055833281088m_5885355420619254025m_-5615617117921539749emb-email-header">
          <font size="1"> <br></font>
        </div>
        <div align="left"
          id="m_-3489806180884640543m_3776847492272040756m_-1384876653450068671m_-5841057114198344580gmail-m_-6730822537853258378gmail-m_-3097863656750721744gmail-m_-602539883475343097m_-7853305765633135062gmail-m_300081265866230006m_2806775055833281088m_5885355420619254025m_-5615617117921539749emb-email-header">
          <span style="border-style:initial;border-color:initial">
            <font size="1"> <img
                src="https://ci5.googleusercontent.com/proxy/KF9Gh_NMzX-0At2EMCOuyGPkpVtxtrN4vTmx3LzJBuzWH0o8_YIoCHn3lryftHpK2p6IX0eEXEenLdfKJYaCmU50Or7aleob3XRMIhNVjrA451M=s0-d-e1-ft#http://i1.cmail19.com/ei/t/18/27D/7A2/000511/csfinal/unnamed.png"
                alt="" style="display:block;border:0px;margin-right:0px" width="334" height="51"> <br></font>
          </span> </div>
      </div>
    </div>
  </div>
  <div
    style="color:rgb(80,0,80);font-family:Roboto,RobotoDraft,Helvetica,Arial,sans-serif;background-color:rgb(220,227,225)">
    <div style="background-color:rgb(255,255,255)">
      <div style="margin:0px auto;max-width:600px;min-width:320px;word-break:break-word">
        <div style="border-collapse:collapse;display:table;width:600px">
          <div style="max-width:600px;min-width:320px;color:rgb(0,0,0);font-size:14px;line-height:21px">
            <div style="margin-left:20px;margin-right:20px">
              <div style="line-height:20px;font-size:1px">
                <font face="arial, sans-serif"> </font>
              </div>
            </div>
            <div style="margin-left:20px;margin-right:20px">
              <div>
                <h1 lang="x-size-40"
                  style="margin-top:0px;margin-bottom:20px;font-weight:normal;font-size:32px;line-height:40px;text-align:center">
                  <span><strong>
                      <font face="arial, sans-serif"> Congratulations! </font>
                    </strong> </span></h1>
              </div>
            </div>
            <div align="center" style="font-size:12px;line-height:19px"> <img alt="" width="600"
                src="https://ci3.googleusercontent.com/proxy/ptZ_ZMQ80Nw2401wL6jHafKuBR3mcdokQcIsmXCppY3nxnLVmIVK8MdsOvvL7qY7UC43nDFRG_bZOCbPsRPvYTVRp34OoJjDNSfC0hVc2R5vJeyjP8Z3rcUAPfhXFNXHd02dug=s0-d-e1-ft#http://i1.cmail19.com/ei/t/18/27D/7A2/000511/csfinal/trophy1-9900000000079e3c.png"
                style="outline:0px;border:0px;display:block;height:auto;width:600px;max-width:900px"> </div>
            <div style="margin-left:20px;margin-right:20px;margin-top:20px">
              <div style="line-height:13px;font-size:1px">
                <font face="arial, sans-serif"> </font>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color:rgb(255,255,255)">
      <div style="margin:0px auto;max-width:600px;min-width:320px;word-break:break-word">
        <div style="border-collapse:collapse;display:table;width:600px">
          <div style="max-width:600px;min-width:320px;color:rgb(0,0,0);font-size:14px;line-height:21px">
            <div style="margin-left:20px;margin-right:20px">
              <p style="margin-top:0px;margin-bottom:0px"> <span>
                  <font face="arial, sans-serif"> Hi *name*, </font>
                </span> </p>
              <p style="margin-top:20px;margin-bottom:0px"> <span>
                  <font face="arial, sans-serif"> Thank you for your consent for OCBC Bank to share your contact details
                    with Funding Societies (FS Bolt). Congratulations, we have pre-approved you for a loan of up to $*amount*.</font>
                </span> </p>
              <p style="margin-top:20px;margin-bottom:0px"> <span>
                  <font face="arial, sans-serif"> FS Bolt is Funding Societies&#39; digital microloan product that aims
                    to finance SMEs at lightning speed.</font>
                </span> </p>
              <p style="margin-top:20px;margin-bottom:0px"> <span>To access your funds in 48 hours, please launch the FS
                  Bolt app, sign in and upload your 2018 & 2019 <font face="arial, sans-serif"> <a
                      href="https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-r/" style="color:rgb(179,129,29)"
                      target="_blank" data -
                      saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-r/&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNHvL4XyTNpXch_1vff4IK7E5z_FpQ">
                      Notice of Assessment</a>.</font></span> You may use the links below to download the app or head
                over to <a href="https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-y/"
                  style="color:rgb(179,129,29);font-family:arial,sans-serif" target="_blank" data -
                  saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-y/&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNF_WXrg1FY4UIofrhp82ngm7vmfdg">
                  https://www.fsbolt.com</a><span style="font-family:arial,sans-serif">.</span>
              </p>
              <p style="margin-top:20px;margin-bottom:0px"> <span>
                  <font face="arial, sans-serif"> Login username: *email* </font>
                </span> </p>
              <p style="margin-top:20px;margin-bottom:0px"> <span>In order to safeguard your account and personal
                  information, we will not be sending your password over email. Instead, please select forgot password and
                  set a password of your choice! </span></p>
              <p style="margin-top:20px;margin-bottom:0px"> <span>
                  <font face="arial, sans-serif"> Please feel free to reach out via email or whatsapp if you have any
                    questions. Welcome to our community, we look forward to working with you! <br> <br>Warm Regards, <br>
                  </font>
                </span>
                <span style="font-family:arial,sans-serif"> *salesname*</span>
              </p>
              <p style="margin-top:20px;margin-bottom:0px"> <span style="font-family:arial,sans-serif"> <br><br></span>
              </p>
            </div>
            <div style="font-family:&quot;PT Serif&quot;,Georgia,serif;margin-left:20px;margin-right:20px">
              <div style="line-height:20px;font-size:1px">
                <a href="https://itunes.apple.com/sg/app/fs-bolt/id1155908897?mt=8"
                  style="font-family:Arial,Helvetica,sans-serif;font-size:12.8px" target="_blank" data -
                  saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://itunes.apple.com/sg/app/fs-bolt/id1155908897?mt%3D8&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNFaaE-2UPT2-TU0bEBovwM3UXAzYQ">
                  <img
                    src="https://ci5.googleusercontent.com/proxy/6_7Qce4Mi6pHXlIKxbDmPEjx92W6kO-f-DzQOqMfgEvBRiFsxf-DspwlOx3l-Tll6b8TahxAO2wlmYNk9DDoc2vMukO_adreZQLhmj4GPY4o51lIndNQaBd9ZflnH7sT0eqYEeplkgaVaOvcXedvpTTw7heVnxlqI56_5uRHkkMIeTWHBQjA8uUnPK8Hc1uMp18RF6XzlES-ilYipA=s0-d-e1-ft#https://docs.google.com/uc?export=download&amp;id=10qo7WL-z_c8ZoixB32taN_kkidAHfJ0w&amp;revid=0B4k6DxeoNz89T1VIelR2OWIreVE2S1V0K0xKditzVWdZN2Q0PQ"
                    width="96" height="30" alt="FS Bolt on the App Store"> </a>
                <span style="font-family:Arial,Helvetica,sans-serif;font-size:12.8px"> </span>
                <a href="https:/ / play.google.com / store / apps / details ? id = com.fundingsocieties.borrower & amp; utm_source = ivie & amp; utm_medium = email"
                  style="font - family: Arial, Helvetica, sans - serif; font - size: 12.8px" target="_blank" data -
                  saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://play.google.com/store/apps/details?id%3Dcom.fundingsocieties.borrower%26utm_source%3Divie%26utm_medium%3Demail&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNHANOt_EzD8YUUOdz7NAPT46xwKtw">
                  <img
                    src="https://ci6.googleusercontent.com/proxy/elr7mcIdmZiZWz-RlbOZQ2yOYmZZJBm4ouqknvanZzn3cPDNzaHRTGmaFuYQ5zhWGAujqJuQclR8AahuPiM7qSzNBSep5Tys7f7s9IUF7_p_aBveklPi9n9V_6qevEPd2IhVVCZwfeB3tcCE7gbaMCS20TuI_X0dxLWxy5tk52f0ZPr-hYTZBfUVNKnFo4m42uOmMpbbb7iPJzBHsA=s0-d-e1-ft#https://docs.google.com/uc?export=download&amp;id=1xfMsbdWznZyjGGqyHVUMpHnWSpOAoIg8&amp;revid=0B4k6DxeoNz89MVBBTUJ3VG91aUVpR0lvNGJzelNTcG43azg4PQ"
                    width="96" height="28" alt="FS Bolt on the Play Store"> </a> </div>
              <div style="line-height:20px;font-size:1px">
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="margin:0px auto;max-width:600px;min-width:320px;word-break:break-word">
        <div style="border-collapse:collapse;display:table;width:600px">
          <div style="font-size:12px;line-height:19px;color:rgb(78,82,81);float:left;max-width:400px;min-width:320px">
            <div style="margin:10px 20px">
              <table style="border-collapse:collapse;table-layout:fixed">
                <tbody>
                  <tr>
                    <td style="padding:0px;width:26px">
                      <a href="https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-j/" style="color:rgb(78,82,81)"
                        target="_blank" data -
                        saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-j/&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNHvs3lRxv9HzcjU783tkPNFC5jlnw">
                        <font face="arial, sans-serif"> <img
                            src="https://ci4.googleusercontent.com/proxy/I8ZWWCmxXQuMYfcypC7krbX5i31tuEp9U4IGB70QrNnIQCqQzk_JKK-EkPi_GFk8gGeeO7W-VRieE1PnMjTCa4s1L_3lonNamdCyW7B_P_8LJkcMqVi_K6-g7cH5BBkn=s0-d-e1-ft#http://i2.cmail19.com/static/eb/master/13-the-blueprint-3/images/facebook.png"
                            width="26" height="26" alt="Facebook" style="border:0px"> </font>
                      </a>
                    </td>
                    <td style="padding:0px 0px 0px 3px;width:26px">
                      <a href="https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-t/" style="color:rgb(78,82,81)"
                        target="_blank" data -
                        saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-t/&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNHeZyNPDZm0BJapQ0O903PEevHMaQ">
                        <font face="arial, sans-serif"> <img
                            src="https://ci4.googleusercontent.com/proxy/wvYSE9N7iMsU0GiTGmSg-i3-bj5QvbwTvDI0M57rd3kBHumDTi9dtJdfa5VA0hJQc2wquWjmxVJoAbEzE_RErU9VK5M4aYzIZH12D22X8kLbJQc7-xYGGwqhthHesXo=s0-d-e1-ft#http://i4.cmail19.com/static/eb/master/13-the-blueprint-3/images/youtube.png"
                            width="26" height="26" alt="YouTube" style="border:0px"> </font>
                      </a>
                    </td>
                    <td style="padding:0px 0px 0px 3px;width:26px">
                      <a href="https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-i/" style="color:rgb(78,82,81)"
                        target="_blank" data -
                        saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-i/&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNEdSAlCxC54cXEjIQUDGwSybDXpbg">
                        <font face="arial, sans-serif"> <img
                            src="https://ci6.googleusercontent.com/proxy/iy80T8VywdkytmBJth4XZm6ciJAcjH-8ZZCkHQ80krbwnYANRjvkSJPUIab1-7jvuUPDRvT7og9gaK3vAH_MqJzUOE5KiNqZEF0sSd2TdnJJ33F7_dUT9du5tECFKtCPwA=s0-d-e1-ft#http://i5.cmail19.com/static/eb/master/13-the-blueprint-3/images/instagram.png"
                            width="26" height="26" alt="Instagram" style="border:0px"> </font>
                      </a>
                    </td>
                    <td style="padding:0px 0px 0px 3px;width:26px">
                      <a href="https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-d/" style="color:rgb(78,82,81)"
                        target="_blank" data -
                        saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://fundingsocieties.cmail19.com/t/t-i-bjioht-l-d/&amp;source=gmail&amp;ust=1565060528993000&amp;usg=AFQjCNE9n9qLA-YCpetqKHoelw6iGfHmZQ">
                        <font face="arial, sans-serif"> <img
                            src="https://ci4.googleusercontent.com/proxy/GUnOhkfyJxURyzyQyPc8yknQgxd-awlj99qKOgXUqRCDrZI477if87_Mqo3MOgl9iWTm_mL-5YwK88qzygFWqWtLwK_cWO8Dg2otLP7qdVTzrPT4aPlJYtAXymooZ-be=s0-d-e1-ft#http://i6.cmail19.com/static/eb/master/13-the-blueprint-3/images/linkedin.png"
                            width="26" height="26" alt="LinkedIn" style="border:0px"> </font>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="line-height:19px;margin-top:20px">
                <div>
                  <font face="arial, sans-serif"> <strong>Location </strong>   <a
                      href="https:/ / www.google.com / maps / search / 10 + Hoe + Chiang + Road ? entry = gmail & amp; source = g">10
                      Hoe Chiang Road</a>, Keppel Towers, #05-08,
                    Singapore 089315 <br> <strong>Office </strong>       + 65 6221 0958<br><strong>Mobile </strong> + 65
                    8778 8693 <br> </font>
                </div>
              </div>
              <div style="line-height:19px;margin-top:18px">
                <div>
                  <font face="arial, sans-serif"> <strong>Disclaimer: </strong> By communicating with Funding Societies or
                    by using our services, you are deemed to have consented to processing of your personal data as
                    set out in the Privacy Notice.This message and any attachment are confidential and intended solely for
                    the use of the individual or entity to whom they are addressed.
                    If you are not the intended recipient: (i) please telephone or e - mail the sender and delete this
                    message and any attachment from your system; (ii) you must not
                    copy this message or attachment or disclose the content to any other person; and(iii) you are notified
                    that disclosing, copying, distributing or taking any action
                    in reliance on the contents of this information is strictly prohibited.We will not be responsible for
                    any illegal and / or improper use of any part of this e - mail.</font>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`,
  },
};

export const SG_BOLT_WHATSAPP_NOTIFICATIONS = [
  { name: 'First touch follow-up', value: 'first_touch_follow_up' },
  { name: 'About FS Bolt', value: 'about_fs_bolt' },
  { name: 'Document request', value: 'document_request' },
  { name: 'Getting CBS', value: 'getting_cbs' },
  { name: 'Not a Moneylender/Legal Entity', value: 'not_moneylender' },
  { name: 'Quantum, Interest and Tenure', value: 'quantum_interest_tenure' },
  { name: 'Risk-Based Pricing', value: 'risk_based_pricing' },
  { name: 'Offer Letter', value: 'offer_letter' },
  { name: 'Contract Signing', value: 'contract_signing' },
  { name: 'Contract Copy', value: 'contract_copy' },
  { name: 'Contract and Giro DDA form', value: 'contract_giro' },
  { name: 'Reminder: Forms', value: 'reminder_forms' },
  { name: 'Loan disbursement', value: 'loan_disbursement' },
  { name: 'Repayment (within grace period)', value: 'repayment_within_grace_period' },
  { name: 'Repayment (after grace period)', value: 'repayment_after_grace_period' },
  { name: 'Renewal Pitch - with Survey', value: 'renewal_pitch_with_survey' },
  { name: 'Closure Letter - Renewed already', value: 'closure_letter_renewed_already' },
  { name: 'Closure Letter - with renewal pitch', value: 'closure_letter_renewal_pitch' },
];

export const SG_BOLT_WHATSAPP_TEMPLATES = {
  first_touch_follow_up:
    'Hi! Ivie here from Funding Societies. Your account manager for FS Bolt. ' +
    'I noticed that you tried our app recently but did not finish the process. ' +
    "Are you looking for business financing? We'd be glad to help!",
  about_fs_bolt:
    'FS Bolt is a mobile app for SME owners to apply for a quick loan with very minimal documentation. ' +
    'Simply download the app on your phone and complete the application so that we can do an assessment free of charge. ' +
    "It's available on both the Apple App Store and Google Play Store.(https://fsbolt.com/).",
  not_moneylender:
    'We are a legal entity with the purpose of offering financial solutions to local SMEs. ' +
    'FS Bolt is a mobile app we have developed for a quick and easy mode of application. ' +
    'You may find out more about us via https://fsbolt.com/',
  document_request:
    "We'll need a copy of your NRIC, past 2 years' NOA, latest CBS and past 6 months' " +
    'business bank statements.',
  getting_cbs: 'You can get the CBS from https://www.creditbureau.com.sg/buy-my-credit-report.html',
  quantum_interest_tenure:
    'We can do loans from $3K-$50K.  Interest rates range between 1.5%-6% per month, ' +
    'with maximum tenure of 12 months. If approved, our offer will be based on the overall assessment ' +
    'of your application.',
  risk_based_pricing:
    'Our credit-scoring system uses a risk pricing model, ' +
    'which means that we will price the interest rate based on the assessed risk.',
  offer_letter:
    "I've sent you our offer letter via email. You may reply to the same email with your acceptance.",
  contract_signing:
    'The link to the econtract has been sent to your email. Please check all details before esigning.',
  contract_copy: "I've sent you the contract copy for your records. Please check your email.",
  contract_giro:
    "I've sent you the contract copy and the required forms. Kindly send us the signed forms within 3 working days.  Please address it to: 91 Bencoolen Street,  #07-01 Sunshine Plaza, Singapore 189652",
  reminder_forms:
    'Hello! We have yet to receive your signed GIRO/DDA form/s. Kindly send us the signed forms within 3 working days.  Please address it to: 91 Bencoolen Street,  #07-01 Sunshine Plaza, Singapore 189652',
  loan_disbursement: 'Your loan has been disbursed. Please check your account to confirm receipt.',
  repayment_within_grace_period:
    'Hello! A gentle reminder that your *month* repayment is still outstanding. Please make the payment by *date* to avoid the late penalty. Thank you.',
  repayment_after_grace_period:
    'Hello! We have yet to receive payment for *month*. Please do a funds transfer to our account as soon as possible. Late fees apply. If you have any issues/concerns please feel free to let me know. Thank you',
  renewal_pitch_with_survey:
    "Hi *name*! Ivie here from Funding Societies. Your account manager for FS Bolt. With one repayment left, your loan with us will soon be completed. Are you looking to apply for a renewal? We'd be glad to help!",
  closure_letter_renewed_already:
    "Thank you for completing the payment for your loan. I've sent you the closure letter for your records. I'd be glad to help you if you wish to apply for a loan renewal.",
  closure_letter_renewal_pitch:
    'Thank you for completing the payment for your loan. I’ve sent you the closure letter for your records.',
};

export const SG_BOLT_PUSH_NOTIFICATIONS = [
  { name: 'First touch follow-up', value: 'first_touch_follow_up' },
  { name: 'About FS Bolt', value: 'about_fs_bolt' },
  { name: 'Document request', value: 'document_request' },
  { name: 'Getting CBS', value: 'getting_cbs' },
  { name: 'Offer Letter', value: 'offer_letter' },
  { name: 'Contract Signing', value: 'contract_signing' },
  { name: 'Contract Copy', value: 'contract_copy' },
  { name: 'Contract and Giro DDA form', value: 'contract_giro' },
  { name: 'Reminder: Forms', value: 'reminder_forms' },
  { name: 'Loan disbursement', value: 'loan_disbursement' },
  { name: 'Repayment (within grace period)', value: 'repayment_within_grace_period' },
  { name: 'Repayment (after grace period)', value: 'repayment_after_grace_period' },
  { name: 'Renewal Pitch - with Survey', value: 'renewal_pitch_with_survey' },
  { name: 'Closure Letter - Renewed already', value: 'closure_letter_renewed_already' },
  { name: 'Closure Letter - with renewal pitch', value: 'closure_letter_renewal_pitch' },
];

export const SG_BOLT_PUSH_NOTIFICATIONS_TEMPLATES = {
  first_touch_follow_up: 'Are you looking for business financing?',
  about_fs_bolt: 'Apply for a quick business loan with us',
  document_request: 'Please submit all documents required',
  getting_cbs: 'Obtain CBS from Credit Bureau Singapore',
  offer_letter: 'Please review your offer',
  contract_signing: 'econtract has been sent to your email',
  contract_copy: 'Contract copy sent',
  contract_giro: 'Please check your email for the forms',
  reminder_forms: 'Please mail us the required forms',
  loan_disbursement: 'Your loan has been disbursed',
  repayment_within_grace_period: 'Gentle reminder on your *MMM* repayment',
  repayment_after_grace_period: 'Late fees apply for your *MMM* repayment',
  renewal_pitch_with_survey: 'Looking to renew your loan?',
  closure_letter_renewed_already: 'Thank you for completing your payment',
  closure_letter_renewal_pitch: 'Closure letter sent to your email',
};

export const M1CBR_LEAD_SOURCE_MAPPINGS = [
  { label: 'Bayarin', value: 'bayarin' },
  { label: 'Etobee', value: 'etobee' },
  { label: 'Akumandiri', value: 'akumandiri' },
  { label: 'Nova Agency', value: 'nova-agency' },
  { label: 'Hendra Agency', value: 'hendra-agency' },
  { label: 'CekRumah', value: 'cekrumah' },
  { label: 'Crowd Verificator', value: 'crowd-verificator' },
  { label: 'Digital', value: 'organic' },
  { label: 'DirectSales', value: 'directsales' },
  { label: 'Triplogic', value: 'triplogic' },
  { label: 'Bukalapak', value: 'bukalapak' },
];

export const MONTH_NAMES_SHORT = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const PHONE_TIME_FRAME_LENGTH = 90;

export const LOAN_DETAIL_SHOW = {
  SG: { show: false },
  MY: { show: false },
  ID: { show: true },
};

export const INTEREST_TYPE = {
  weekly: 7,
  monthly: 30,
  yearly: 360,
};

export const TENOR_TYPE = {
  weekly: 7,
  monthly: 30,
  yearly: 360,
};

export const LOAN_GRADE = {
  A: 'A',
  E: 'E',
};

export const FILTER_LOAN_STATUS = {
  SG: [
    { id: 1, name: 'ORI-INCOMPLETE' },
    { id: 3, name: 'ORI-PROCESS' },
    { id: 4, name: 'ORI-DOCS-PENDING' },
    { id: 6, name: 'ORI-REJECT' },
    { id: 22, name: 'UND-NEW' },
    { id: 23, name: 'UND-PROCESS' },
    { id: 45, name: 'UND-DOCS-PENDING' },
    { id: 24, name: 'UND-APPROVE' },
    { id: 25, name: 'UND-REJECTED' },
    { id: 27, name: 'PRE-OFFER' },
    { id: 28, name: 'OFFER-SENT' },
    { id: 29, name: 'BRW-ACCEPT' },
    { id: 30, name: 'BRW-REJECT' },
    { id: 31, name: 'FUN-NEW' },
    { id: 33, name: 'FUN-FUNDING' },
    { id: 35, name: 'FUN-FAIL' },
    { id: 36, name: 'FUN-DISBURSE-REQUEST' },
    { id: 37, name: 'FUN-DISBURSED' },
    { id: 38, name: 'SET-ONGOING' },
    { id: 39, name: 'SET-COMPLETE' },
    { id: 40, name: 'SET-DEFAULT' },
    { id: 52, name: 'SET-WRITE-OFF' },
  ],
  MY: [
    { id: 1, name: 'ORI-INCOMPLETE' },
    { id: 3, name: 'ORI-PROCESS' },
    { id: 4, name: 'ORI-DOCS-PENDING' },
    { id: 6, name: 'ORI-REJECT' },
    { id: 22, name: 'UND-NEW' },
    { id: 23, name: 'UND-PROCESS' },
    { id: 45, name: 'UND-DOCS-PENDING' },
    { id: 24, name: 'UND-APPROVE' },
    { id: 25, name: 'UND-REJECTED' },
    { id: 27, name: 'PRE-OFFER' },
    { id: 28, name: 'OFFER-SENT' },
    { id: 29, name: 'BRW-ACCEPT' },
    { id: 30, name: 'BRW-REJECT' },
    { id: 31, name: 'FUN-NEW' },
    { id: 33, name: 'FUN-FUNDING' },
    { id: 36, name: 'FUN-DISBURSE-REQUEST' },
    { id: 37, name: 'FUN-DISBURSED' },
    { id: 38, name: 'SET-ONGOING' },
    { id: 39, name: 'SET-COMPLETE' },
    { id: 40, name: 'SET-DEFAULT' },
    { id: 52, name: 'SET-WRITE-OFF' },
  ],
  ID: [
    { id: 1, name: 'ORI-INCOMPLETE' },
    { id: 2, name: 'ORI-NEW' },
    { id: 3, name: 'ORI-PROCESS' },
    { id: 4, name: 'ORI-DOCS-PENDING' },
    { id: 5, name: 'ORI-DOCS-COMPLETE' },
    { id: 6, name: 'ORI-REJECT' },
    { id: 22, name: 'UND-NEW' },
    { id: 23, name: 'UND-PROCESS' },
    { id: 46, name: 'UND-PROCESS-SITEVISIT' },
    { id: 43, name: 'UND-VERIF-CALL' },
    { id: 45, name: 'UND-DOCS-PENDING' },
    { id: 44, name: 'UND-DOCS-COMPLETE' },
    { id: 24, name: 'UND-APPROVE' },
    { id: 25, name: 'UND-REJECTED' },
    { id: 27, name: 'PRE-OFFER' },
    { id: 28, name: 'OFFER-SENT' },
    { id: 29, name: 'BRW-ACCEPT' },
    { id: 30, name: 'BRW-REJECT' },
    { id: 32, name: 'FUN-PRE' },
    { id: 31, name: 'FUN-NEW' },
    { id: 33, name: 'FUN-FUNDING' },
    { id: 36, name: 'FUN-DISBURSE-REQUEST' },
    { id: 37, name: 'FUN-DISBURSED' },
    { id: 34, name: 'FUN-SUCCESS' },
    { id: 38, name: 'SET-ONGOING' },
    { id: 39, name: 'SET-COMPLETE' },
    { id: 40, name: 'SET-DEFAULT' },
    { id: 41, name: 'SET-COLLECTION' },
    { id: 52, name: 'SET-WRITE-OFF' },
  ],
};

export const TRANSACTION_TYPE = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
};

export enum BANK_BALANCE_FREQUENCY {
  Monthly,
  Weekly,
  Daily,
}
export const UND_REJECT_STATUS_ID = 25;
export const ORI_REJECT_STATUS_ID = 6;

export const M1CB_COLLECTION_ACTIVITY_CODES = [
  { value: 'Call', name: 'Call' },
  { value: 'Visit', name: 'Visit' },
  { value: 'WA', name: 'WA' },
];

export const M1CB_COLLECTION_MEETING_WITHS = [
  { value: 'Borrower', name: 'Borrower' },
  { value: 'Spouse', name: 'Spouse' },
  { value: 'Staff', name: 'Staff' },
  { value: 'Others', name: 'Others' },
];

export const M1CB_COLLECTION_HANDLING_STATUSES = [
  { value: 'No Answer', name: 'No Answer' },
  { value: 'Not Active', name: 'Not Active' },
  { value: 'Wrong Number', name: 'Wrong Number' },
  { value: 'Paid', name: 'Paid' },
  { value: 'PTP', name: 'PTP' },
  { value: 'Refuse To Pay', name: 'Refuse To Pay' },
  { value: 'Leave Message', name: 'Leave Message' },
  { value: 'Other', name: 'Other' },
];

export const M1CB_HOME_OWNERSHIP_STATUSES = [
  { value: 'Owned', name: 'Owned' },
  { value: 'Family Owned', name: 'Family Owned' },
  { value: 'Contract/Lease/Rental', name: 'Contract/Lease/Rental' },
  { value: 'Official Residence', name: 'Official Residence' },
];

export const VALIDITY_STATUSES = [
  { value: 'Valid', name: 'Valid' },
  { value: 'Invalid', name: 'Invalid' },
];

export const M1CB_BUSINESS_CONDITIONS = [
  { value: 'Stable', name: 'Stable' },
  { value: 'Weaken', name: 'Weaken' },
  { value: 'Bankrupt', name: 'Bankrupt' },
];

export const M1CB_COLLECTION_MAIN_ISSUES = [
  { value: 'Belum gajian', name: 'Belum gajian' },
  { value: 'Jobless', name: 'Jobless' },
  { value: 'Usaha menurun', name: 'Usaha menurun' },
  { value: 'Internal problem', name: 'Internal problem' },
  { value: 'Banyak tagihan', name: 'Banyak tagihan' },
  { value: 'Bencana', name: 'Bencana' },
  { value: 'Sakit, Meninggal', name: 'Sakit, Meninggal' },
  { value: 'Tidak kooperatif, tidak mau bayar', name: 'Tidak kooperatif, tidak mau bayar' },
  { value: 'Kapasitas bayar tidak mencukupi', name: 'Kapasitas bayar tidak mencukupi' },
  { value: 'Pinjaman atas nama', name: 'Pinjaman atas nama' },
];

export enum COMPANY_ENTITY {
  private_limited = 18,
  public_listed = 19,
  public_limited = 20,
}

export const CBS_RISK_GRADE = [
  'AA',
  'BB',
  'CC',
  'DD',
  'EE',
  'FF',
  'GG',
  'HH',
  'HX',
  'HZ',
  'GX',
  'BX',
  'CX',
  'UNKNOWN',
];

export const BRI_PAYMENT_GRADE = ['STRONG', 'GOOD', 'FAIR', 'MARGINAL', 'WEAK', 'POOR', 'UNKNOWN'];

export const ALLOWED_VIEW_DOC_EXTENTION = ['pdf', 'jpg', 'jpeg', 'png', 'gif'];

export const OTHER_BANK_ID = {
  ID: '9993',
};

export const DDA_REQUIREMENTS = [
  { label: 'Pre disbursal', value: 'pre_disbursal' },
  { label: 'Post disbursal', value: 'post_disbursal' },
];

export const PRE_DISBURSEMENT = [
  { label: 'Direct Debit', value: 'direct_debit' },
  { label: 'Post Dated Cheque', value: 'post_dated_cheque' },
];

export const DDA_REQUIREMENT_DEFAULT = 'post_disbursal';
export const PRE_DISBURSEMENT_DEFAULT = 'direct_debit';

export const BOLT_SG_BANK_LIST = [
  'BANK OF CHINA LIMITED',
  'BANK OF INDIA',
  'CITIBANK N.A.',
  'CITIBANK SINGAPORE LIMITED',
  'DBS BANK LTD',
  'HSBC BANK (SINGAPORE) LIMITED',
  'MAYBANK SINGAPORE LIMITED',
  'OVERSEA-CHINESE BANKING CORPORATE LIMITED',
  'RHB BANK BERHAD',
  'STANDARD CHARTERED BANK',
  'UNITED OVERSEAS BANK LIMITED',
  'OTHER',
];

export const MY_TYPE_GRADES = ['AA', 'A', 'BB', 'B', 'CC', 'C', 'DD', 'D', 'E'];
