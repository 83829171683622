const th_configuration = {
  country_code: 'TH',
  currency: 'THB',
  date_format: {
    date: 'dd MMMM yyyy',
    date_short: 'yyyy-MM-dd',
    date_short_moment: 'YYYY-MM-DD',
    date_short_dmy: 'dd/MM/yyyy',
    date_time: 'dd MMMM yyyy HH:mm:ss',
    date_time_not_second: 'yyyy-MM-dd HH:mm',
    locale: 'en-TH',
    timezone: 'Asia/Bangkok',
  },
  year_difference: 543,
  number_format: {
    align: 'left',
    allowNegative: false,
    decimal: '.',
    precision: 2,
    prefix: '฿',
    suffix: '',
    thousands: ',',
    locale: 'en',
  },
  pipe: {
    precision: '1.2-2',
  },
  language: {
    default: 'en',
    show: false,
  },
  loan: {
    underwriting: {
      guaranteeFee: {
        show: false,
      },
    },
  },
  enable_mail_disbursal: true,
  enable_ctos: false,
  enable_questnet: false,
  enable_pefindo: false,
  show_generate_instruction: false,
  show_dopa_card_status_check: true,
  show_escrow_withdrawal_dropdown: true,
  show_second_identity_card_number: true,
  second_identity_card_number_title: 'Laser ID',
  display_crowdfunding_segmentation: true,
  should_auto_send_activation_notification: false,
  hide_pii_post_kyc: true,
  hide_pii_in_member_detail: true,
  primary_ic_type_id: 25, // National ID,
  show_deposit_table_reason_column: true,
  investor_detail: {
    show_ic_expiry_date: true,
  },
  show_investor_bond_certificate_download: true,
  statements: {
    excluded_event_types: ['DEPOSIT', 'WITHDRAWAL'],
  },
  hide_blank_dropdown_options: {
    industry: true,
  },
  phoneValidation: {
    code: '+66',
    phoneNumberLengthWithCode: [12, 13],
    phoneNumberLengthWithoutCode: [9, 10],
  },
  show_user_type_column_in_facility_user: true,
  show_bca_va_reconciliation_upload: false,
  use_custom_investor_management_pagination: true,
  use_custom_borrower_management_pagination: true,
  use_custom_partner_management_pagination: true,
  add_doc_type_and_loan_code_to_loan_agreement_filename: false,
  defaultBranch: 'TH',
  show_distribution_gst: true,
  show_risk_category_fields: true,
  show_wht_breakdown: true,
  toggleAdminFeeHelperText: true,
  inclusive_withholding_tax: true,
  has_usd_to_local_currency_conversion: false,
  calculator: {
    settlement: {
      show_gst: true,
    },
  },
  capture_loan_disbursement_and_repayment_methods: true,
  updateLoanDisbursementMethodUsingBankCountryParty: true,
  enablePushToSilkRoad: true,
  defaultPreApprovedValues: {
    lateFee: 1000,
    facilityFeeType: 'FLAT',
    lateInterestFee: 0.1,
    autoApprove: false
  },
  showFacilityContractDate: true,
  showLineId: true
};

export default th_configuration;
