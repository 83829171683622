import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './shared/components/not-found-page/not-found-page.component';

import { memberRoute } from 'app/routes/member.route';
import { facilityRoute } from 'app/routes/facility.route';
import { loanRoute } from 'app/routes/loan.route';
import { financeRoute } from 'app/routes/finance.route';
import { utilitiesRoute } from 'app/routes/utilities.route';
import { microLoanRoute } from 'app/routes/micro-loan.route';
import { loanCollectionRoute } from 'app/routes/loan-collection.route';
import { collectionRoute } from 'app/routes/collection.route';
import { collectionOfficerRoute } from 'app/routes/collection-officer.route';
import { elevateRoute } from './routes/elevate.route';
import { investorChannelingRoutes } from './routes/investor-chan.route';

const commonRoutes: Routes = [
  {
    path: 'Home',
    loadChildren: () => import('./routes/home.route').then((x) => x.HomeRoutes),
  },
  {
    path: 'not-found-page',
    component: NotFoundPageComponent,
  },
  {
    path: '',
    redirectTo: 'Home',
    pathMatch: 'full',
  },
];

export const appRoutes: Routes = [
  memberRoute,
  facilityRoute,
  elevateRoute,
  loanRoute,
  investorChannelingRoutes,
  ...financeRoute,
  utilitiesRoute,
  microLoanRoute,
  loanCollectionRoute,
  collectionRoute, // TODO: move under /loan-collection
  collectionOfficerRoute, // TODO: move under /loan-collection
  ...commonRoutes,
];

export const routing = RouterModule.forRoot(appRoutes);
