import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyFormatPipe } from '@fs-design/widgets';
import { DateFormatPipe } from '../date-format/date-format.pipe';
import { DateTimeFormatPipe } from '../date-format/date-time-format.pipe';
import { TitleCasePipe } from '@angular/common';
@Pipe({
    name: 'dynamic'
  })
  export class DynamicPipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyFormatPipe,
        private datePipe: DateFormatPipe,
        private dateTimeFormatPipe: DateTimeFormatPipe,
        private titleCasePipe: TitleCasePipe,
    ) {}
    transform(value: any, pipe: string): any {
      if (!pipe) { return value; }
      if (pipe === 'currencyMaskPipe') {
          return this.currencyPipe.transform(value);
      }
      if (pipe === 'dateFormat') {
          return this.datePipe.transform(value);
      }
      if (pipe === 'dateTimeFormat') {
        return this.dateTimeFormatPipe.transform(value);
    }
      if (pipe === 'titleCase' && (value !== null && value !== undefined)) {
          return this.titleCasePipe.transform(value.toString());
      }
      return value;
    }
  }
